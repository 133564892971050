/****************************************************************************
 * Copyright 2018 EPAM Systems
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *    http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 ***************************************************************************/

import Vec2 from '../../util/vec2';

import op from '../shared/op';
import Action from '../shared/action';

export function fromChiralFlagAddition(restruct, pos) { // eslint-disable-line no-unused-vars
	var action = new Action();
	var struct = restruct.molecule;
	if (restruct.chiralFlags.size < 1) {
		if (!pos) {
			var bb = struct.getCoordBoundingBox();
			var posY = !struct.isBlank() ? bb.min.y - 1 : bb.min.y + 1;
			pos = new Vec2(bb.max.x, posY);
		}
		action.addOp(new op.ChiralFlagAdd(pos).perform(restruct));
	}
	return action;
}

export function fromChiralFlagDeletion(restruct) {
	var action = new Action();
	action.addOp(new op.ChiralFlagDelete());
	return action.perform(restruct);
}
